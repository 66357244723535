import VanillaModal from 'vanilla-modal';

const modal = new VanillaModal({
	modal:'.modal'
});

jQuery(document).ready(function($){

	$('.modal-shows:not(".current-menu-item") a, a.modal-shows').on('click', function(e){
		e.preventDefault();
		modal.open('#modal-shows');
		window.dispatchEvent(new Event('resize'));
		return false;
	});
});