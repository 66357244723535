jQuery(document).ready(function($){

	
	//sortable table columns for songlist
	$("table.songlist").each(function(){
		var table = $(this);
		table.find("th").each(function(){
			$(this).attr("data-sort","string");
		});
		table.find("th").first().each(function(){
			$(this).attr("data-sort-onload","yes");
		});
		table.stupidtable();
	});
	
	//gallery block needs to fix rel attribute so lightbox fwd/prev buttons show
	$(".wp-block-gallery").each(function(){
		var gallery = $(this);
		gallery.find(".blocks-gallery-item a").each(function(){
			$(this).attr("data-lightbox-gallery","lightbox");
		});
		//gallery.find(".blocks-gallery-item a").nivoLightbox();
	});
	
	
	
});//jQuery(document).ready(function($){
